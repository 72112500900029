@import './../Utils.scss';

.hc--mood-info {
  border-top: 1px solid $gray-4;

  .hc--mood-info-title {
    @include text($color: $black, $fontSize: 21px, $fontWeight: 500);
  }

  .hc--mood-info-description {
    margin-top: 20px;
    @include text($color: $dark-gray-5, $fontSize: 16px, $fontWeight: 400);
    line-height: 25px;
  }
}

.hc--mood-selection-container {
  .hc--select-box-container {
    .hc--single-select-box-content {
      height: 35px;
      display: flex;
      align-items: center;
      .hc--mood-select-box-data {
        display: flex;
        align-items: center;
      }

      .hc--mood-info-price {
        text-transform: uppercase;
        margin-left: 10px;
        padding: 0 16px 1px;
        border-radius: 20px;
        @include text($color: $white, $fontSize: 16px, $fontWeight: 800);
        border: 2px solid $black;
        height: 26px;
        display: flex;
        align-items: center;
      }
    }
  }
}
