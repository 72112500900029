// IMPORT FONT "SegoeRegular"
@font-face {
  font-family: SegoeRegular;
  src: url("../fonts/SegoeUI.ttf") format("truetype");
}

html,
body,
#root,
.home-configurator-container {
  height: 100%;
  font-family: primary-font, SegoeRegular, sans-serif;
}

#root {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// COMPONENTS - CONTROLS
@import "SCSS/components/common/controls/buttons.scss";
@import "SCSS/components/common/controls/links.scss";
@import "SCSS/components/common/controls/modals.scss";
@import "SCSS/components/common/controls/select.scss";
@import "SCSS/components/common/controls/selectBox.scss";
@import "SCSS/components/common/controls/imageSelectBox.scss";
@import "SCSS/components/common/controls/snackbars.scss";
@import "SCSS/components/common/controls/tooltips.scss";

// COMPONENTS
@import "SCSS/components/common/apartmentInfo.scss";
@import "SCSS/components/common/icons.scss";
@import "SCSS/components/common/header.scss";
@import "SCSS/components/common/footer.scss";
@import "SCSS/components/common/breadcrumb.scss";
@import "SCSS/components/common/loader.scss";
@import "SCSS/components/common/sliders.scss";
@import "SCSS/components/common/progressBar.scss";
@import "SCSS/components/common/circularLoader.scss";

// PAGES
@import "SCSS/pages/PageTemplate.scss";
@import "SCSS/pages/Mood.scss";
@import "SCSS/pages/Specification.scss";
@import "SCSS/pages/Finish.scss";
@import "SCSS/pages/Optionals.scss";
@import "SCSS/pages/Summary.scss";
@import "SCSS/pages/ErrorPage.scss";
@import "SCSS/pages/HomePage.scss";
@import "SCSS/pages/FullScreenTemplate.scss";

// GENERIC STYLE
@import "SCSS/GenericStyle.scss";
