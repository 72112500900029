@import './../Utils.scss';

.hc--optionals {
  .hc--optionals-container {
    margin-top: 20px;
    box-sizing: border-box;

    .hc--single-optional-container {
      box-sizing: border-box;
      outline: 1px solid $gray-9;
      border-radius: 2px;
      margin: 1px 0px;
      display: flex;
      align-items: center;
      width: 100%;
      box-shadow: 0px 3px 12px rgba(129, 129, 129, 0.2);

      &.selected {
        outline-width: 2px;
        margin: 0px;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      .hc--single-optional-image {
        height: 120px;
        width: auto;
        flex: 0.33;
        max-width: 33%;
        object-fit: cover;
        object-position: center;
      }

      .hc--single-optional-content {
        padding: 0 20px;
        flex: 0.67;

        .hc--single-optional-title {
          @include flexContainer($align-items: center);
          @include text($fontSize: 16px, $color: $black, $fontWeight: 600);

          .hc--optional-info {
            display: inline-flex;
            margin-left: 5px;
            cursor: pointer;
            padding: 0;
            background-color: #999999;
          }
        }

        .hc--single-optional-price-container {
          margin-top: 25px;
          @include flexContainer($justify-content: space-between, $align-items: center);

          .hc--optional-price {
            @include flexContainer($justify-content: center, $align-items: center);
            box-sizing: border-box;
            @include text($color: $black, $fontSize: 14px, $fontWeight: 700);
            padding: 3px 10px;
            border-radius: 100px;
            background-color: none;
            border: 2px solid white;
          }

          .hc--add-optional-button-container {
            @include flexContainer($justify-content: center, $align-items: center);

            .hc--add-remove-optional-button {
              background-color: $black;
              border-radius: 2px;
              padding: 5px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 2px;

              span {
                display: inline-flex;

                .icon {
                  transition: transform 0.2s;
                }
              }

              &.to-add {
                span.icon {
                  transform: rotate(0deg);
                }
              }

              &.to-remove {
                border-width: 2px;

                span.icon {
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.float-right {
  float: right;
}
