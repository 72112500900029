.progressbar-wrapper {
  position: relative;
}

.progressbar {
  transform: rotate(-90deg); /* Fix the orientation */
}

.progessbar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.progessbar-label__progress {
  font-size: 1.5em;
  font-weight: bold;
  display: block;
}
