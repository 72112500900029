.homepage-apartment-title {
  padding: 32px 20px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1a1a1a;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.homepage--planimetry {
  display: flex;
  justify-content: center;

  img {
    width: 280px;
  }
}

.hc--homepage-central-image {
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  .hc--homepage-img-content {
    padding-left: 86px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hc--homepage-title {
    font-style: normal;
    font-weight: 350;
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
  }

  .hc--homepage-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #ffffff;
  }

  .hc--homepage-start-button {
    margin-top: 20px;
  }
}

.hc--homepage-details-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 20px 0px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 12px rgba(204, 204, 204, 0.25);
  border-radius: 2px;

  > .single-sup-container {
    @include flexContainer($align-items: center, $justify-content: space-between);
  }

  .single-sup-container {
    padding: 24px;
    border-bottom: 1px solid #eeeeee;

    .icon {
      margin-right: 10px;
    }

    .single-sup-info-container {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
      height: 100%;
    }

    .sup-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      /* identical to box height */
      letter-spacing: 0.01em;

      /* grigi/$neutral-30 */
      color: #4d4d4d;
    }

    .sup-value {
      @include text($color: $dark-gray-3, $fontWeight: 700, $fontSize: 16px);
    }
  }
}
