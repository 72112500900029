.MuiTooltip-tooltip {
  background-color: #3b3b3b !important;
  border-radius: 0 !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.625rem;
  width: auto;
  text-align: center;
  .MuiTooltip-arrow {
    color: #3b3b3b;
  }
}
