.apartment-info-container {
  .apartment-info--planimetry {
    padding: 0px 20px;
    display: flex;
    justify-content: center;

    img {
      width: 280px;
    }
  }

  .apartment-info--details {
    padding: 40px 20px;
    @include flexContainer($align-items: center, $direction: column);

    .apartment-info--title {
      @include text($color: $black, $fontWeight: 700, $fontSize: 32px);
    }

    .apartment-info--subtitle {
      @include text($color: $gray-5, $fontWeight: 400, $fontSize: 16px);
      margin-top: 10px;
    }
  }

  .apartment-info--details-container {
    width: 100%;
    @include flexContainer($flex-wrap: wrap, $justify-content: center);
    margin-top: 20px;

    > .single-sup-container {
      flex: 45%;
      @include flexContainer($align-items: center);
    }

    .single-sup-container {
      margin-top: 20px;
      margin-left: 5px;

      .vertical-divisor {
        width: 1px;
        height: 90%;
        background-color: $gray-6;
      }

      .icon {
        margin-right: 10px;
      }

      .single-sup-info-container {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-grow: 1;
        height: 100%;
      }

      .sup-title {
        @include text($color: $gray-5, $fontWeight: 400, $fontSize: 12px);
      }

      .sup-value {
        margin-top: 10px;
        @include text($color: $dark-gray-3, $fontWeight: 700, $fontSize: 16px);
      }
    }
  }
}

.apartment-info-details-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 32px 0 0;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 12px rgba(204, 204, 204, 0.25);
  border-radius: 2px;

  > .single-sup-container {
    @include flexContainer($align-items: center, $justify-content: space-between);
  }

  .single-sup-container {
    padding: 24px;
    border-bottom: 1px solid #eeeeee;

    .icon {
      margin-right: 10px;
    }

    .single-sup-info-container {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
      height: 100%;
    }

    .sup-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      /* identical to box height */
      letter-spacing: 0.01em;

      /* grigi/$neutral-30 */
      color: #4d4d4d;
    }

    .sup-value {
      @include text($color: $dark-gray-3, $fontWeight: 700, $fontSize: 16px);
    }
  }
}
