@import './../../Utils.scss';

.hc--header {
  background-color: white;
  padding: 0 24px;
  box-shadow: 0px 1px 12px 2px rgba(229, 230, 237, 0.87);
  z-index: 10;
  @include flexContainer($direction: row, $align-items: center);
  height: 72px;
  > div {
    width: 50%;
  }

  &-controls.text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    outline: unset;
    padding: 0;
  }

  &-right {
    @include flexContainer($justify-content: flex-end);
    padding: 24px 0;
  }

  &-left {
    @include flexChildren($flex-grow: 1);
    height: 100%;
    padding: 16px 0;
    box-sizing: border-box;

    > img {
      width: auto;
      height: 100%;
    }
  }

  .icon {
    height: 24px;
    width: 24px;
    background-size: 18px;
    padding: 0;
    margin: 0 5px 0 0;
  }
}

.header-right-element {
  border: 0;
  padding: 0px;
  margin-right: 24px;
}

.header-right-last {
  border: 0;
  padding: 0px;
}
