@import './../../../Utils.scss';

.hc--link {
  cursor: pointer;
  display: inline-block;

  @include text($color: $black, $fontWeight: 500, $fontSize: 16px);

  &.underlined {
    &:hover {
      border-bottom: 1px solid $black;
    }
  }
}
