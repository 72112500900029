.dropdown {
  display: flex;
  position: relative;
}

.dropdown-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 5;
  width: 150px;
  border-radius: 2px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open,
.overlay-dropdown.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  background-color: lightgray;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  // transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  // transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(180deg);
}

.overlay-dropdown {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.dropdown .dropdown-header .icon {
  height: 16px;
  width: 16px;
  background-size: 10px;
  margin-left: 3px;
}
