@import './../Utils.scss';

.slide-wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .slide-container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;

    &.left {
      transition: left 0.2s linear 0s;
    }
    &.right {
      transition: right 0.2s linear 0s;
    }
  }

  &.closed {
    visibility: hidden;
    height: 0;
    width: 0;

    .slide-container {
      &.left {
        left: 100%;
      }
      &.right {
        right: 100%;
      }
    }
  }

  &.open {
    .slide-container {
      &.left {
        left: 0%;
      }
      &.right {
        right: 0%;
      }
    }
  }
}

.home-configurator-container {
  @include flexContainer($direction: column);

  .hc--single-page {
    @include flexChildren($flex-grow: 1);

    .hc--generic-page-wrapper {
      width: 100%;
      height: 100%;
      @include flexContainer($direction: column);
    }

    .hc--generic-page-container {
      overflow: hidden;
      @include flexContainer($direction: row);
      @include flexChildren($flex-grow: 1);

      .hc--generic-page-center {
        @include flexChildren($flex-grow: 1);
        @include flexContainer($direction: column);

        .hc--generic-page-center-content {
          height: 100%;
          width: 100%;
          position: relative;

          .hc--generic-page-image-container {
            height: 100%;
            width: 100%;

            .hc--generic-page-image {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &.animated-images {
              position: relative;

              .hc--generic-page-image {
                position: absolute;
                top: 0%;
                left: 0%;
                opacity: 0;
                // transition: opacity 400ms ease-in-out;
                z-index: -1;

                &.active {
                  opacity: 1;
                  z-index: 1;
                  // transition: opacity 400ms ease-in-out 400ms;
                  background-position: center;
                }
              }
            }
          }

          .hc--generic-page-image-slider-container {
            width: inherit;
            height: inherit;
          }
        }
      }

      .hc--generic-page-right-sidebar {
        z-index: 3;
        order: 99999;
        width: 400px;
        background-color: white;
        border-left: 1px solid $gray-4;
        border-top: 1px solid $gray-4;
        overflow: hidden;

        .hc--generic-page-right-sidebar-container {
          height: 100%;
          position: relative;
        }

        .hc--apartment-info-trigger {
          padding: 0px 20px;
          min-height: 70px;
          cursor: pointer;
          border-bottom: 1px solid $gray-4;
          align-items: center;

          &.close-trigger {
            border-bottom: none;

            span.icon {
              margin-top: 2px;
              margin-right: 20px;
            }
          }

          &.open-trigger {
            justify-content: space-between;
          }

          @include flexContainer($align-items: center);

          @include text($color: $black, $fontSize: 21px, $fontWeight: 600);
        }
      }
    }
  }

  .hc--generic-page-right-sidebar {
    .hc--generic-page-right-sidebar-container {
      @include flexContainer($direction: column);
      .hc--generic-page-selections-container {
        padding: 13px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e7e7e7;

        .hc--generic-page-selections-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px;

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;

          .hc--generic-page-selection-info {
            font-weight: 600;
          }
        }
      }
    }

    .hc--page-right-sticky-info {
      border-top: 1px solid $gray-4;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      z-index: 2;

      .hc--page-right-single-info {
        @include flexContainer($direction: row, $justify-content: space-between, $align-items: center);

        .hc--page-right-single-info-title {
          @include text($color: $black, $fontSize: 18px, $fontWeight: 400);
        }

        .hc--page-right-single-info-value {
          @include text($color: $black, $fontSize: 24px, $fontWeight: 800);
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .hc--page-right-action-container {
        margin-top: 10px;

        > .hc--button {
          display: block;
          text-align: center;
        }
      }
    }
  }

  .hc--generic-padding {
    padding: 15px 20px;
  }

  .hc--right-sidebar-section {
    .hc--select-box-container {
      margin-top: 20px;
      width: 100%;
    }
  }

  .hc--right-sidebar-section,
  .hc--page-right-sticky-info {
    padding: 15px 24px 20px;
  }

  .hc--page-secondary-content-sidebar,
  .hc--generic-page-right-sidebar .hc--page-right-content {
    @include flexChildren($flex-grow: 1);
    box-sizing: border-box;
    z-index: 2;

    .hc--page-title {
      margin-top: 10px;
      @include text($color: $black, $fontSize: 24px, $fontWeight: 700);
    }

    .hc--page-subtitle {
      margin-top: 10px;
      @include text($color: $dark-gray-2, $fontSize: 18px, $fontWeight: 400);
    }

    .hc--generic-page-right-siderbar-container {
      width: 100%;
    }
  }

  .hc--page-secondary-content-sidebar {
    width: 400px;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(100% - 400px);
    border-left: 1px solid $gray-4;
    box-sizing: border-box;
    transition: left 0.5s ease;

    .hc--page-secondary-contant-wrapper {
      width: 100%;
    }

    .hc--secondary-big-title {
      border-bottom: 1px solid $gray-4;
      padding-top: 30px;
      padding-bottom: 30px;
      @include flexContainer($justify-content: space-between, $align-items: center);

      .hc--secondary-big-title-wrapper {
        @include text($color: $black, $fontWeight: 600, $fontSize: 21px);
      }

      .hc--secondary-first-choice-wrapper {
        border: 1px solid $black;
        padding: 5px 10px;
        border-radius: 20px;
        @include text($color: $black, $fontSize: 16px, $fontWeight: 600);
      }
    }

    .hide-secondary-sidebar {
      background-color: white;
      border: 1px solid $gray-4;
      position: absolute;
      top: 35px;
      right: 100%;
      height: 40px;
      width: 40px;
      transition-duration: 0.5s;
      transition-property: transform;
    }

    .hc--page-secondary-contant-wrapper {
      overflow: hidden;
    }

    &.close {
      left: 100%;

      .hide-secondary-sidebar {
        transform: rotate(-180deg);
      }
    }

    &.fullscreen {
      width: 100%;
      left: 0%;
      z-index: 1;
    }
  }

  .hc--generic-page-secondary-siderbar-container {
    height: 100%;
    .hc--secondary-sidebar-section {
      height: 100%;
    }
  }

  .hc--page-secondary-content-sidebar {
    &.fullscreen {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &:not(.fullscreen) {
      background-color: white;
    }
  }
}

.hc--initial-selection {
  background-blend-mode: multiply, normal, normal, normal;
  background-position: center;
  background-size: cover;
  padding: 15px;
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 8px 0px;
  }

  .hc--initial-selection-mood {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
  }

  .hc--initial-selection-specification {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .hc--button {
    color: white;
    border: 2px solid white;
    border-radius: 2px;
  }
}
