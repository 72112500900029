@import './../../Utils.scss';

.hc--breadcrumb-row-container {
  &.with-arrows {
    @include flexContainer();

    .breadcrumb-arrow-container {
      border: 1px solid $gray-9;
      width: 40px;
      height: 71px;
      border-bottom: none;
      box-sizing: border-box;
      @include flexContainer($justify-content: center, $align-items: center);
    }

    .hc--breadcrumb-scroll-container {
      height: 71px;
      width: calc(100% - 40px);
    }

    .hc--breadcrumb-container {
      > .hc--breadcrumb-item {
        @include flexChildren($flex-grow: 1, $flex-shrink: 1);
      }
    }
  }
}

.hc--breadcrumb-scroll-container {
  height: 71px;
}

.hc--breadcrumb-container {
  width: 100%;
  display: inline-flex;
  border-top: 1px solid $gray-9;

  > .hc--breadcrumb-item {
    @include flexChildren($flex-grow: 1);
    flex: 1 1 0px;

    &:not(:first-child) {
      border-left: 1px solid $gray-9;
    }
  }

  .hc--breadcrumb-item {
    background-color: $gray-1;
    padding: 0px 20px;
    height: 70px;
    @include flexContainer($align-items: center);

    .hc--breadcrumb-item-index,
    .hc--breadcrumb-item-title {
      @include text($color: $gray-2, $fontWeight: 500, $fontSize: 16px);
    }

    .hc--breadcrumb-item-title {
      margin-top: 8px;
    }

    .hc--breadcrumb-item-index {
      min-height: 21px;
      min-width: 21px;
      border-radius: 50%;
      padding: 1px;
      border: 1px solid $gray-2;
      @include flexContainer($justify-content: center, $align-items: center);

      > span {
        line-height: 16px;
        text-align: center;
      }
    }

    .hc--breadcrumb-text-container {
      @include flexContainer($direction: column);

      margin-left: 16px;

      .hc--breadcrumb-item-subtitle-separator {
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $gray-2;
        border-radius: 50%;
        background-color: $gray-2;
        width: 1px;
        height: 1px;
        display: inline-flex;
      }

      .hc--breadcrumb-item-subtitle-content {
        white-space: nowrap;
        text-transform: uppercase;
      }
    }

    .hc--breadcrumb-item-subtitle {
      @include text($color: $gray-2, $fontWeight: 400, $fontSize: 11px);
      @include flexContainer($align-items: center);

      > span {
        @include flexContainer($align-items: center);
      }
    }

    // Colors
    &.active {
      background-color: $white;
    }
    &.past {
      background-color: $white;
    }
    &.future {
      background-color: $gray-1;
      * {
        color: $neutral-70 !important;
      }
    }

    &.past {
      cursor: pointer;
    }

    &.active {
      position: relative;

      .hc--breadcrumb-item-index {
        background-color: $black;
        color: $white;
      }

      .hc--breadcrumb-item-title {
        color: $dark-gray;
        font-weight: 700;
      }

      .hc--breadcrumb-item-subtitle {
        color: $dark-gray;
      }

      .hc--breadcrumb-item-subtitle-separator {
        background-color: $dark-gray;
        border-color: $dark-gray;
      }

      &:before {
        content: '';
        height: 3px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: $black;
      }
    }

    &.past {
      .hc--breadcrumb-item-index {
        background-color: $dark-gray;
        color: $white;
      }

      .hc--breadcrumb-item-title {
        color: $dark-gray;
        font-weight: 500;
      }

      .hc--breadcrumb-item-subtitle {
        color: $dark-gray;
      }

      .hc--breadcrumb-item-subtitle-separator {
        background-color: $dark-gray;
        border-color: $dark-gray;
      }
    }
  }
}
