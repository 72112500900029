@import './../../../Utils.scss';

.hc--image-select-box-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .hc--image-select-box {
    cursor: pointer;
    outline: 2px solid white;
    box-shadow: 0px 3px 12px rgba(129, 129, 129, 0.2);
    border-radius: 2px;
    position: relative;
    display: flex;

    // width: 100%;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // box-sizing: border-box;

    .hc--image-select-box-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .hc--image-select-box-imagebox-image {
        height: 85px;
        background-size: 100% 100%;
      }

      .hc--image-select-box-single-main-content {
        padding: 8px 16px;

        .hc--image-select-box-single-imagebox-name {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 153%;
          color: #1a1a1a;

          display: flex;
          align-items: center;

          .hc--image-select-box-single-imagebox-name-text {
            // Ellipsis
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            max-width: 100px;
          }
        }

        .hc--image-select-box-single-imagebox-description {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 153%;
          height: 45px;
          overflow-wrap: anywhere;
          color: #808080;

          // Ellipsis
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;

          &-text {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;
          }
        }
      }

      .hc--image-select-box-info-price-container {
        @include flexContainer($justify-content: center, $align-items: center);
        white-space: nowrap;

        &.hc--image-select-box-info-card {
          display: flex;
          justify-content: flex-end;
          padding: 16px;
        }

        .hc--icon-button.arrow-down {
          margin-left: 10px;
        }

        .hc--image-select-box-info-price {
          padding: 3px 15px;
          @include text($color: $black, $fontSize: 14px, $fontWeight: 700);
          line-height: 16px;
          border-radius: 20px;
          text-transform: uppercase;
          border: 2px solid $black;
        }
      }
    }

    &.selected {
      border-color: $black;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: lightgrey;
    }

    .hc--image-select-box-check-container {
      position: absolute;
      display: inline-flex;
      top: -1px;
      right: -1px;
      background-color: $black;
      border-bottom-left-radius: 2px;

      > span {
        margin-right: 1px;
      }
    }
  }
}
