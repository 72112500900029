.hc--fullscreen-page-wrapper {
  width: 100%;
}

.hc--fullscreen-page-container {
  height: 100%;
}

.hc--fullscreen-page-center {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.hc--fullscreen-page-upper-content {
  padding-top: 30px;
  height: 210px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.hc--fullscreen-page-bottom-content {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.25%, #000000 100%);
  color: white;

  .hc--full-screen-select-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .hc--full-screen-select-box {
      padding: 10px;
      text-align: center;
      width: 150px;
      margin: 10px;
      border: 1px solid #d1d1d1;
      cursor: pointer;
      background: rgba(209, 209, 209, 0.2);
      backdrop-filter: blur(4px);
      border-radius: 2px;
    }
  }

  .hc--fullscreen-page-bottom-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    padding-left: 30px;

    .hc--fullscreen-page-stepper {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #b3b3b3;

      .horizontal-line {
        width: 77px;
        height: 0px;
        margin: 0 20px;

        border: 1px solid #b3b3b3;
      }

      .active {
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;

        .horizontal-line {
          border: 1px solid white;
        }
      }
    }
  }

  .hc--fullscreen-page-bottom-center {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .hc--fullscreen-page-bottom-right {
    padding-right: 30px;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .hc--fullscreen-page-confirm {
      min-width: 150px;
    }

    .hc--fullscreen-page-bottom-right-inner {
      position: relative;

      .hc--fullscreen-total-cost-info {
        position: absolute;
        width: 100%;
        top: -40px;
        display: flex;
        justify-content: space-between;

        .hc--fullscreen-total-cost-info-left {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 34px;
        }

        .hc--fullscreen-total-cost-info-right {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }
}
