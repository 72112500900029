@import './../../Utils.scss';

.hc--footer {
  background-color: $black;
  @include flexContainer($justify-content: center);
  padding: 5px 0px;

  > .hc--link {
    color: $white;
    font-size: 13px;
    font-weight: 400;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
