@import './../../../Utils.scss';

.hc--select-box-container {
  @include flexContainer($direction: column);

  display: inline-flex;
  gap: 20px;

  .hc--single-select-box {
    border: 2px solid $gray-6;
    border-radius: 2px;
    min-height: 150px;
    position: relative;
    cursor: pointer;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;

    @include flexContainer(column, $justify-content: flex-end, $align-items: center);

    @include text($color: $white, $fontSize: 18px, $fontWeight: 700);

    .hc--single-select-box-content {
      padding: 16px;
      width: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.selected {
      border-color: $black;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: lightgrey;
    }

    .hc--single-select-box-check-container {
      position: absolute;
      display: inline-flex;
      top: -1px;
      right: -1px;
      background-color: $black;
      border-bottom-left-radius: 2px;

      > span {
        margin-right: 1px;
      }
    }
  }

  &:not(.with-image-minimal) {
    .hc--single-select-box:not(.selected) {
      // box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
      filter: grayscale(1);
    }

    .hc--single-select-box {
      .hc--single-select-box-content {
        font-weight: 600;
      }

      &.selected {
        .hc--single-select-box-content {
          font-weight: 700;
        }
      }
    }
  }
}
