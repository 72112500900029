@import './../Utils.scss';

.error-page {
  height: 100%;
  width: 100%;
  @include flexContainer($justify-content: center, $align-items: center);

  .main-wrapper {
    width: 60%;
    text-align: center;

    h1 {
      margin: 0px;
      @include text($color: $black, $fontSize: 36px, $fontWeight: 800);
    }

    p {
      max-width: 500px;
      @include text($color: $dark-gray-5, $fontSize: 21px, $fontWeight: 500);
    }

    .hc--button {
      min-width: 300px;
    }
  }
}
