.hc--finish-upper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;

  .hc--page-title {
    margin-top: 0 !important;
  }
}

.hc--finish-info {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hc--finish-description-title {
    @include text($color: $black, $fontSize: 21px, $fontWeight: 600);
    margin-bottom: 6px;
  }

  .hc--finish-info {
    margin-left: 10px;
    padding: 5px 15px;
    background-color: $white;
    border: 2px solid #6bc1b4;
    border-radius: 82.7099px;
    @include text($color: $black, $fontSize: 16px, $fontWeight: 600);
  }
}

.floatingContent-trigger {
  width: 100%;
}

.hc--finish-category-select-upper-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;

  .hc--category-name {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    color: #808080;
  }

  .hc--category-change-button {
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
}

.finish-category-progress-label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #666666;
}

.hc--finish-info-price-container {
  @include flexContainer($justify-content: center, $align-items: center);
  white-space: nowrap;
  flex-shrink: 0;

  &.hc--finish-info-card {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 15px 10px;
  }

  .hc--icon-button.arrow-down {
    margin-left: 10px;
  }

  .hc--finish-info-price {
    padding: 3px 15px;
    @include text($color: $black, $fontSize: 14px, $fontWeight: 700);
    line-height: 16px;
    border-radius: 20px;
    text-transform: uppercase;
    border: 2px solid $black;
  }
}

.hc--image-card {
  outline: 1px solid #e6e6e6;
  box-shadow: 0px 3px 12px rgba(163, 163, 163, 0.2);
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  align-items: center;
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;

  .hc--image-card-div {
    height: 88px;
    width: 88px;
    min-width: 88px;
    max-width: 88px;
    flex: 1;
    background: #f8f8f8;
    border-radius: 2px 0px 0px 2px;
    background-position: center;
    background-size: cover;

    &.placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon.finish {
      background: #e6e6e6;
    }
  }

  .hc--image-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    flex: 2;
    padding: 0 16px;
    align-items: center;
    width: 100%;
    overflow: hidden;

    .icon {
      padding: 5px;
      width: 24px;
      height: 24px;
    }
  }

  .hc--image-card-children {
    padding: 5px;
    display: flex;
    flex-direction: column;

    &.placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 153%;
      align-items: center;
      color: #808080;
      white-space: pre-wrap;
    }
  }

  .hc--selected-finish-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .hc--selected-finish-name-text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      word-wrap: break-word;
    }
  }
}

.hc--finish-image-slider-container {
  display: none;

  &.slider-active {
    display: block;
  }
}

.hc--finish-images-container {
  display: none;

  &.images-active {
    display: block;
  }
}

.hc--finish-selection-container {
  margin-top: 20px;

  .hc--select-box-container {
    .hc--single-select-box-content {
      padding: unset;
      //   height: 35px;

      //   .hc--specification-select-box-data {
      //     display: flex;
      //     align-items: center;
      //   }

      //   .hc--specification-info-price {
      //     text-transform: uppercase;
      //     margin-left: 10px;
      //     padding: 5px 15px;
      //     border-radius: 20px;
      //     @include text($color: $white, $fontSize: 16px, $fontWeight: 800);
      //     border: 2px solid $black;
      //   }
    }
  }
}
