@import './../Utils.scss';

.hc--specification-navigation {
  flex: 0.2;

  .hc--button {
    color: white;
    border: none;
  }
}

.hc--specification-title-container {
  flex-direction: row;

  .hc--specification-title {
    font-style: normal;
    line-height: 18px;
    text-transform: uppercase;
    @include text($color: $white, $fontSize: 24px, $fontWeight: 400);
  }

  .hc--specification-info-price {
    text-transform: uppercase;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 20px;
    @include text($color: $white, $fontSize: 16px, $fontWeight: 800);
    border: 2px solid $black;
  }
}

.hc--specification-info-container {
  flex: 0.6;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hc--specification-description {
  @include text($color: $white, $fontWeight: 400, $fontSize: 16px);

  font-style: normal;
  line-height: 22px;
  margin-top: 20px;
  flex: 3;
  text-align: center;
}

.hc--specification-info {
  border-top: 1px solid $gray-4;

  .hc--specification-info-title {
    @include text($color: $black, $fontSize: 21px, $fontWeight: 500);
  }

  .hc--specification-info-description {
    margin-top: 20px;
    @include text($color: $dark-gray-5, $fontSize: 16px, $fontWeight: 400);
    line-height: 25px;
  }

  .hc--specification-modal-description-trigger {
    margin-top: 20px;
    font-weight: bold;
  }

  .hc--specification-info-price {
    text-transform: uppercase;
    margin-left: 10px;
    padding: 5px 15px;
    background-color: $black;
    border-radius: 20px;
    @include text($color: $white, $fontSize: 16px, $fontWeight: 800);
  }
}

.hc--specification {
  .hc--secondary-sidebar-section {
    &.fullscreen-layout {
      .hc--secondary-big-title {
        justify-content: flex-start;
        border-bottom: none;
        padding-bottom: 0px;

        .hc--secondary-big-title-wrapper {
          @include text($color: $black, $fontSize: 24px, $fontWeight: 500);
        }

        .hc--secondary-first-choice-wrapper {
          margin-left: 5px;

          @include text($color: $black, $fontSize: 24px, $fontWeight: 700);
          border: none;
          padding: 0;
        }
      }

      .hc--page-subtitle {
        @include text($color: $dark-gray-2, $fontSize: 18px, $fontWeight: 400);
      }
    }
  }

  .hc--button {
    &.disabled {
      background-color: #cccccc !important;
      border-color: white !important;
      color: white !important;

      &::before {
        background-color: unset;
      }
    }
  }
}

.hc--specification-selection-container {
  .hc--select-box-container {
    .hc--single-select-box-content {
      height: 35px;

      .hc--specification-select-box-data {
        display: flex;
        align-items: center;
      }

      .hc--specification-info-price {
        text-transform: uppercase;
        margin-left: 10px;
        padding: 0 16px 1px;
        border-radius: 20px;
        @include text($color: $white, $fontSize: 16px, $fontWeight: 800);
        border: 2px solid $black;
        height: 26px;
        display: flex;
        align-items: center;
      }
    }
  }
}
