@import './../Utils.scss';

.hc--summary {
  .hc--summary-info-container {
    .hc--summary-single-info {
      padding: 25px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray-9;
      }

      .hc--summary-info-row-container {
        @include flexContainer($justify-content: space-between, $align-items: center);

        .hc--summary-info-row {
          padding: 0 16px;
        }

        &:not(:first-child) {
          margin-top: 25px;
        }
      }

      .hc--summary-info-title-container {
        @include flexContainer($justify-content: space-between, $align-items: center);

        .hc--summary-info-index {
          background-color: $black;
          @include text($color: $white, $fontSize: 18px, $fontWeight: 500);
          width: 25px;
          height: 25px;
          @include flexContainer($justify-content: center, $align-items: center);
          display: inline-flex;
          border-radius: 50%;
        }

        .hc--summary-info-title-subtitle {
          @include flexContainer($direction: column);
          margin-left: 10px;

          .hc--summary-info-title {
            @include text($color: $black, $fontSize: 18px, $fontWeight: 700);

            margin-top: 4px;
          }

          .hc--summary-info-subtitle {
            @include text($color: $black, $fontSize: 13px, $fontWeight: 400);
            text-transform: uppercase;

            @include flexContainer($justify-content: space-between);

            > span {
              @include flexContainer($align-items: center, $direction: row);
              display: inline-flex;
            }
          }

          .hc--summary-item-subtitle-separator {
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid $gray-2;
            border-radius: 50%;
            background-color: $gray-2;
            width: 1px;
            height: 1px;
            display: inline-flex;
          }
        }
      }

      .hc--summary-info-name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .floatingContent-trigger {
        width: 100%;
      }

      .hc--summary-info-value {
        @include text($color: $black, $fontSize: 16px, $fontWeight: 600);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;

        .hc--summary-single-optional {
          width: 100%;
          margin-top: 10px;
          @include flexContainer($justify-content: space-between, $align-items: center);

          .hc--summary-single-optional-price {
            padding: 2px 16px;
            @include text($color: $black, $fontSize: 16px, $fontWeight: 700);
            border-radius: 20px;
            text-transform: uppercase;
            border: 2px solid $black;
          }
        }
      }

      .hc--sumary-info-price-container {
        @include flexContainer($justify-content: center, $align-items: center);
        white-space: nowrap;
        max-width: 14.5rem;
        flex-shrink: 0;

        .hc--icon-button.arrow-down {
          margin-left: 10px;
        }

        .hc--sumary-info-price {
          padding: 2px 16px;
          @include text($color: $black, $fontSize: 14px, $fontWeight: 700);
          border-radius: 20px;
          text-transform: uppercase;
          border: 2px solid $black;
        }
      }
    }
  }

  .hc--generic-page-sticky-footer-content {
    > div {
      width: 100%;
      box-sizing: border-box;
      text-align: center;

      font-weight: 700;
    }

    .hc--summary-download-button {
      margin-top: 13px;
    }
  }

  .hc--summary-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    .hc--summary-column {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .hc--summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .hc--image-card {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .hc--summary-category-name {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;

    letter-spacing: 0.01em;

    color: #808080;
  }
}
