$black: #1a1a1a;
$white: white;
$gray-1: #f8f8f8;
$gray-2: #c1c1c1;
$gray-3: #ededed;
$gray-4: #e7e7e7;
$gray-5: #727272;
$gray-6: #cecece;
$gray-7: #e5e5e5;
$gray-8: #e8e8e8;
$gray-9: #e6e6e6;
$gray-10: #9f9f9f;
$dark-gray: #444444;
$dark-gray-2: #7a7a7a;
$dark-gray-3: #343434;
$dark-gray-4: #dcdcdc;
$dark-gray-5: #4d4d4d;
$dark-gray-6: #666666;

$neutral-10: #1a1a1a;
$neutral-50: #808080;
$neutral-70: #b3b3b3;

$redError: #f24822;

@mixin flexContainer($direction: null, $align-items: null, $align-content: null, $justify-content: null, $flex-wrap: null) {
  display: -webkit-flex;
  display: flex;
  flex-direction: $direction;
  -webkit-flex-direction: $direction;
  align-items: $align-items;
  align-content: $align-content;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
}

@mixin flexChildren($flex-grow: null, $flex-shrink: null, $flex-basis: null, $align-self: null) {
  display: flex;
  flex-grow: $flex-grow;
  flex-shrink: $flex-shrink;
  flex-basis: $flex-basis;
  align-self: $align-self;
}

@mixin text($color: unset, $fontSize: 12px, $fontFamily: null, $fontWeight: 500) {
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-family: $fontFamily;
}
