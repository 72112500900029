@import './../../../Utils.scss';

.hc--generic-snackbar {
  position: fixed;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  padding: 20px;
  z-index: 99999999999999999999;
  transition: top 0.5s linear 0s;
  top: -200px;

  &.showing {
    top: 8px;
  }

  &.error-snackbar {
    background-color: $redError !important;
    color: $white !important;

    @include flexContainer($align-items: center);

    .error-snackbar-text {
      @include flexChildren($flex-grow: 1);
      margin-left: 10px;
    }
  }
}

.hc--generic-snackbar.sw-snackbar {
  display: flex;
  width: auto !important;
  padding: 6px 16px;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  align-items: center;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: rgb(49, 49, 49) !important;
  color: white !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  &.showing {
    top: 20px;
  }

  > .snackbar-body {
    color: white !important;
    width: 100%;

    > .update-button {
      padding: 5px;
      color: white !important;
      border: none;
      font-weight: 400;
      padding-left: 16px;
      font-size: 16px;
    }
  }

  .loader-container {
    width: auto;
    position: initial;
    transform: unset;
    padding: 20px;

    .spinner {
      width: auto;
      display: inline-flex;
    }
  }
}
