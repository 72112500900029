@import './../../../Utils.scss';

.hc--button {
  border: 1px solid $black;
  border-radius: 2px;
  padding: 13px 28px;
  cursor: pointer;
  display: inline-block;
  @include text($color: $black, $fontWeight: 700, $fontSize: 16px);

  &.disabled {
    position: relative;
    cursor: not-allowed;

    &::before {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      top: 0px;
      left: 0px;
      position: absolute;
      content: '';
    }
  }

  &.loading {
    position: relative;

    .button-content {
      visibility: hidden;
    }

    .button-loader {
      top: 0%;
      left: 0;
      height: 100%;
      transform: unset;
    }
  }

  &.inverted {
    background-color: $black;
    color: white;
  }

  &.link {
    padding: 0px;
    padding-bottom: 2px;
    border: none;
    border-bottom: 1px solid $black;
  }
}

.hc--icon-button {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &.disabled {
    cursor: not-allowed;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
