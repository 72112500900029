.hc-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.69);
  z-index: 100000000;

  &.showing {
    .hc-modal--container {
      top: 50%;
    }
  }

  &.hide {
    visibility: hidden;
  }

  &.hiding {
    .hc-modal--container {
      top: 150%;
    }
  }

  .hc-modal--container {
    // transition: top 0.3s ease-in-out 0s;
    position: absolute;
    background-color: white;
    left: 50%;
    top: 150%;
    transform: translate(-50%, -50%);
    padding: 24px;

    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
    }

    .hc--modal-action-button-container {
      margin-top: 30px;
      @include flexContainer();

      > * {
        @include flexChildren($flex-grow: 1);
        justify-content: center;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .hc--modal-title {
      width: 100%;
      text-align: center;
      overflow-wrap: break-word;

      @include text($color: $black, $fontSize: 21px, $fontWeight: 700);
    }

    .hc--modal-body {
      margin-top: 25px;

      @include text($color: $black, $fontSize: 16px, $fontWeight: 500);
    }
  }
}

.support-modal {
  .hc-modal--container {
    min-width: 520px;
    min-height: unset;

    .hc--modal-body {
      .hc--button {
        margin-top: 20px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
    }

    .contact-options {
      margin-top: 20px;
      .single-contact-option {
        @include flexContainer($align-items: center);

        > span {
          display: inline-flex;
        }

        .icon {
          margin-right: 12px;
        }

        .vertical-divider {
          width: 1px;
          margin-right: 12px;
          height: 18px;
          background-color: $dark-gray-4;
        }

        .hc--link {
          font-weight: 700;
        }
      }
    }
  }
}

.disclaimer-modal {
  .hc-modal--container {
    max-width: 528px;
    .hc--modal-body {
      display: flex;
      flex-direction: column;
      line-height: 30px;
      align-items: center;
      .hc--button {
        margin-top: 20px;
        line-height: 21px;
      }
    }
  }
}

.quit-modal {
  .hc-modal--container {
    max-width: 480px;

    .hc--modal-body {
      line-height: 25px;
    }
  }
}

.back-to-page-modal {
  .hc-modal--container {
    max-width: 520px;

    .hc--modal-body {
      line-height: 25px;
    }
  }
}

.optional-modal {
  .hc-modal--container {
    padding: 0px;
    width: 500px;

    .hc--modal-body {
      margin-top: 0px;
    }

    .hc--modal-title {
      padding: 1.5rem 3rem 0 1.5rem;
      box-sizing: border-box;
    }

    .hc--optional-modal-image {
      height: 250px;
      width: 500px;
      margin-top: 20px;
      background-position: center;
      background-size: cover;
    }

    .hc--optional-modal-description {
      @include text($color: $black, $fontWeight: 500, $fontSize: 16px);
      line-height: 30px;
      white-space: pre-line;

      .hc--optional-modal-description-text {
        padding: 20px 25px 35px 25px;
        overflow-wrap: anywhere;
      }
    }
  }
}

.modal-info {
  .hc-modal--container {
    padding: 0px;
    width: 500px;

    .hc--modal-body {
      margin-top: 0px;
    }

    .hc--modal-title {
      padding: 1.5rem 3rem 0 1.5rem;
      box-sizing: border-box;
      // Ellipsis
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
    }

    .hc--modal-image {
      height: 250px;
      width: 500px;
      margin-top: 20px;
      background-size: cover;
      background-position: center;
    }

    .hc--modal-description {
      @include text($color: $black, $fontWeight: 500, $fontSize: 16px);
      line-height: 30px;
      white-space: pre-line;

      .hc--modal-description-text {
        padding: 20px 25px 35px 25px;
        overflow-wrap: anywhere;
      }
    }
  }
}

.confirm-configuration-modal {
  .hc-modal--container {
    max-width: 526px;
  }
}

.modal-cancel-button,
.modal-confirm-button {
  flex: 1;
}

.confirm-quote-status-modal {
  .hc-modal--container {
    max-width: 450px;

    .hc--modal-title {
      @include flexContainer($direction: column, $justify-content: center, $align-items: center);

      .hc--modal-check {
        background-color: $black;
        border-radius: 50%;
        padding: 4px;
      }

      .hc--modal-title-text {
        margin-top: 10px;
      }
    }

    .hc--modal-description {
      @include text($color: $black, $fontWeight: 500, $fontSize: 16px);
      line-height: 30px;
    }

    .hc--modal-action-button {
      @include flexContainer($direction: column);
      margin-top: 20px;

      .hc--modal-back-to-myhome {
        text-align: center;
      }

      .hc--modal-resend-container {
        margin-top: 10px;
        @include flexContainer($direction: row, $justify-content: center);

        .hc--modal-resend {
          margin-left: 5px;
          @include text($color: $black, $fontSize: 16px, $fontWeight: 800);
        }

        .hc--modal-not-received-container {
          @include text($color: $black, $fontSize: 16px, $fontWeight: 500);
        }
      }
    }
  }
}

.save-configuration-modal {
  .hc-modal--container {
    max-width: 450px;
    min-width: 420px;
    padding: 8px;
    .close {
      top: 8px;
      right: 8px;
    }
    .save-configuration-modal-title {
      display: flex;
      justify-content: center;
      padding-top: 24px;
      @include text($color: $black, $fontSize: 18px, $fontWeight: 900);
    }
    .save-configuration-container {
      padding: 16px 24px 24px 24px;
      .save-configuration-modal-body {
        display: flex;
        flex-flow: column;
        .save-configuration-modal-description {
          @include text($color: $black, $fontSize: 12px, $fontWeight: 500);
          text-align: center;
        }
        .save-configuration-modal-input {
          margin-top: 24px;
          max-height: 40px;
          border: 1px solid #000000;
          padding: 12px 16px;
          font-size: 12px;
          border-radius: 0px;
          font-family: 'SegoeRegular';
        }
        .save-configuration-modal-input::placeholder {
          color: #bfbfbf;
          font-size: 12px;
          font-weight: 400;
          font-family: 'SegoeRegular';
        }
        .save-configuration-modal-input:focus {
          outline: none;
        }
        .save-configuration-modal-helper-text {
          color: red;
          font-size: 10px;
          padding-top: 5px;
        }
      }
      .save-configuration-bottons-container {
        margin-top: 24px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .save-configuration-back-button {
          width: 125px;
          border: 1px solid;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .save-configuration-save-button {
          width: 125px;
          border: 1px solid;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.image-modal {
  .hc-modal--container {
    height: 80%;
    max-height: 700px;

    .hc--modal-image-container {
      height: 95%;
      width: 700px;
      margin-top: 10px;
    }
    img.modal-image {
      width: 100%;
    }
  }
}
