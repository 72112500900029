@import './../../Utils.scss';

.hc--slider-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    width: 100%;
    height: 100%;
  }

  .hc--single-slide-container {
    height: 100%;
    width: 100%;

    .hc--single-slide {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .hc--single-slide-details {
        margin: 25px;
        background: rgba(23, 23, 23, 0.8);
        border-radius: 2px;

        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 16px;

        color: white;

        .hc--single-slide-subtitle {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          /* identical to box height */
          letter-spacing: 0.01em;
          text-transform: uppercase;
        }

        .hc--single-slide-title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          padding-top: 8px;
        }
      }
    }
  }

  .slider-arrow-prev,
  .slider-arrow-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border-radius: 2px;
    background-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-arrow-prev {
    left: 20px;
  }
  .slider-arrow-next {
    right: 20px;
  }
}
