@media (min-width: 992px) {
  #mobile-content {
    display: none;
  }
}
@media print {
  #mobile-content {
    display: none !important;
  }
}

#outerDiv {
  height: 100%;
}

#mobile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999999999999;
  background-color: white;

  .mobile-message {
    max-width: 700px;
    display: flex;
    font-size: 30px;
    line-height: 1.5em;
    text-align: center;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.flex-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
  display: inline-block;
}

.white-background {
  background-color: white !important;
}

.hc--generic-page-image-area {
  position: relative;
  width: 100%;
  height: 100%;
}
.full-screen-icon-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;

  .icon {
    display: block;
  }
}

.hc--generic-page-image-area.image-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
